// React
import React from "react";
// Style
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height || "10"}%;
  width: ${(props) => props.width || "80"}%;
  background-color: ${(props) => props.backgroundColor || "#fff"};
  border-radius: 50px;
  ${(props) => props.border && `border: 2px solid ${props.border}`}
`;

const TextStyled = styled.span`
  font-size: clamp(20px, 5vw, 70px);
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Button = ({ height, width, backgroundColor, border, text, action }) => {
  return (
    <Container
      height={height}
      width={width}
      backgroundColor={backgroundColor}
      border={border}
      onClick={() => action()}
    >
      <TextStyled>{text}</TextStyled>
    </Container>
  );
};

export default Button;
