// React
import React, { useEffect, useState } from "react";
// Style
import styled, { keyframes } from "styled-components";

const Circle = styled.figure`
  display: block;
  border: 3px solid #795bd0;
  background: white;
  border-radius: 50%;
  height: 500px;
  width: 500px;
  @media (max-width: 768px) {
    height: 300px;
    width: 300px;
  }
  position: relative;
  margin: 0;
`;

// Pattern 1:
const moveBall = keyframes`
  0% {
    transform: none;
  }
  25% {
    transform: translateX(-100%) translateY(-350%);
  }
  50% {
    transform: translateX(-250%) translateY(-60%);
  }
  75% {
    transform: translateX(-100%) translateY(50%);
  }  
  100% {
    transform: none;
  }
`;

// Pattern 2:
const moveBall2 = keyframes`
  0% {
    transform: none;
  }
  25% {
    transform: translateX(0%) translateY(270%);
  }
  50% {
    transform: translateX(250%) translateY(200%);
  }
  75% {
    transform: translateX(220%) translateY(-40%);
  }  
  100% {
    transform: none;
  }
`;

// Pattern 3:
const moveBall3 = keyframes`
  0% {
    transform: none;
  }
  33% {
    transform: translateX(-150%) translateY(200%);
  }
  66% {
    transform: translateX(170%) translateY(200%);
  }
  100% {
    transform: none;
  }
`;

// Pattern 4:
const moveBall4 = keyframes`
  0% {
    transform: none;
  }
  25% {
    transform: translateX(100%) translateY(-350%);
  }
  50% {
    transform: translateX(200%) translateY(0%);
  }
  75% {
    transform: translateX(-50%) translateY(-150%);
  }  
  100% {
    transform: none;
  }
`;

//Pattern 5:
const moveBall5 = keyframes`
  0% {
    transform: none;
  }
  20% {
    transform: translateX(-170%) translateY(-100%);
  }
  40% {
    transform: translateX(160%) translateY(-280%);
  }
  60% {
    transform: translateX(-180%) translateY(-265%);
  }  
  80% {
    transform: translateX(170%) translateY(-130%);
  } 
  100% {
    transform: none;
  }
`;

//Pattern 6:
const moveBall6 = keyframes`
  0% {
    transform: none;
  }
  33% {
    transform: translateX(0%) translateY(230%);
  }
  66% {
    transform: translateX(-300%) translateY(0%);
  }
  100% {
    transform: none;
  }
`;

const Ball = styled.figure`
  width: 20%;
  height: 20%;
  background: ${(props) => props.color || "white"};
  border-radius: 50%;
  position: absolute;
`;

// Ball 1
const Ball1 = styled(Ball)`
  margin: 70% 60%;
  animation: ${moveBall} 2s linear infinite;
`;

// Ball 2
const Ball2 = styled(Ball)`
  margin: 20% 20%;
  animation: ${moveBall2} 2s linear infinite;
`;

// Ball 3
const Ball3 = styled(Ball)`
  margin: 20% 40%;
  animation: ${moveBall3} 2s linear infinite;
`;

// Ball 4
const Ball4 = styled(Ball)`
  margin: 70% 20%;
  animation: ${moveBall4} 2s linear infinite;
`;

// Ball 5
const Ball5 = styled(Ball)`
  margin: 75% 40%;
  animation: ${moveBall5} 2s linear infinite;
`;

// Ball 6
const Ball6 = styled(Ball)`
  margin: 20% 70%;
  animation: ${moveBall6} 2s linear infinite;
`;

const colors = ["red", "blue", "green", "orange", "purple", "gray"];

const BingoAnimation = () => {
  const [color, setColor] = useState([]);

  useEffect(() => {
    //shuffle the color array
    const shuffledColors = colors.sort(() => Math.random() - 0.5);
    setColor(shuffledColors);
  }, []);

  return (
    <Circle>
      <Ball1 color={color[0]} />
      <Ball2 color={color[1]} />
      <Ball3 color={color[2]} />
      <Ball4 color={color[3]} />
      <Ball5 color={color[4]} />
      <Ball6 color={color[5]} />
    </Circle>
  );
};

export default BingoAnimation;
