// 1 - import svgs
import Cog from "images/icons/cog.svg";
import ArrowCircleRight from "images/icons/arrow-circle-right.svg";

// 2 - SVGList
const iconMap = {
  Cog,
  ArrowCircleRight,
};
export default iconMap;
