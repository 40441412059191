//React
import React from "react";
//PropTypes
import PropTypes from "prop-types";
//Style
import styled from "styled-components";
// Redux
import { useDispatch, useSelector } from "react-redux";
// Actions
import {
  changeMaxNumber,
  changeAnimation,
  changeSound,
  changeAutoPlay,
  changeInterval,
  changeAnimationDuration,
} from "../../actions/loto";
// Utils
import Switch from "react-switch";
import Icon from "utils/icon";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "./messages";

const Container = styled.button`
  position: fixed;

  left: 5%;
  top: 10%;

  width: 90%;
  height: 80%;

  border: 2px solid #011627;
  border-radius: 1em;

  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: #000;
`;

const Param = styled.div`
  display: flex;
  width: 95%;
  justify-content: center;
  align-items: center;
  padding: 5% 0px;
  border-top: solid 1px gray;
`;

const Title = styled.div`
  display: flex;
  width: 50%;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  font-size: clamp(10px, 3vw, 50px);
  font-weight: bold;
`;

const SubTitle = styled.div`
  display: flex;
  font-size: 1em;
  font-weight: normal;
`;

const Action = styled.div`
  display: flex;
  width: 50%;
  justify-content: end;
  align-items: center;
`;

const H1 = styled.span`
  font-size: clamp(30px, 5vw, 100px);
  font-weight: bold;
  margin-left: 10px;
`;

const IconStyled = styled(Icon)`
  width: 3vh;
  height: 3vh;
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const TitleSection = styled.div`
  display: flex;
  width: 95%;
  align-items: center;
  padding: 30px 0px;
`;

const Buttons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  bottom: 0;
`;

const Input = styled.input`
  appearance: none;
  width: 100%;
  @media (max-width: 768px) {
    height: 15px;
  }
  height: 30px;
  border-radius: 20px;
  background: rgb(211, 211, 211);
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s ease 0s;
  &::-webkit-slider-thumb {
    @media (max-width: 768px) {
      width: 20px;
      height: 20px;
    }
    appearance: none;
    width: 40px;
    height: 40px;
    background: green;
    border-radius: 50%;
  }
`;

const SwitchStyled = styled(Switch)`
  @media (min-width: 768px) {
    margin-right: 20px;
    transform: scale(2);
  }
`;

const Button = styled.div`
  background: linear-gradient(
    162.44deg,
    #85b6ff 0%,
    rgba(106, 53, 255, 0.5) 100%
  );
  font-size: clamp(20px, 5vw, 70px);
  border-radius: 50px;
  height: 100%;
  padding: 3% 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height}%;
  width: ${(props) => props.width}%;
  color: #fff;
`;

const Reload = styled.span`
  font-size: clamp(12px, 3vw, 70px);
`;

const ParamsValid = styled.div`
  display: flex;
  width: 100%;
  align-items: space-around;
  flex-direction: column;
  justify-content: end;
  margin: 40px 0px;
  position: absolute;
  height: 20%;
  bottom: 0;
`;

const PopUp = ({
  setPopUp,
  resetGame,
  setPause,
  setRemainTime,
  setStartAutoPlay,
}) => {
  const {
    maxNumber,
    animation,
    animationDuration,
    sound,
    autoplay,
    interval,
    needToReload,
  } = useSelector((state) => state.loto);

  const dispatch = useDispatch();
  const changeNumber = (value) => dispatch(changeMaxNumber(value));
  const changeAnimationAction = (value) => dispatch(changeAnimation(value));
  const changeAnimationDurationAction = (value) =>
    dispatch(changeAnimationDuration(value));
  const changeSoundAction = (value) => dispatch(changeSound(value));
  const changeAutoPlayAction = (value) => dispatch(changeAutoPlay(value));
  const changeIntervalAction = (value) => dispatch(changeInterval(value));

  const reset = () => {
    setPopUp(false);
    setPause(false);
    setRemainTime(interval);
    setStartAutoPlay(false);
    resetGame();
  };

  //function for convert ms into seconds
  const convertTime = (time) => {
    const seconds = Math.floor(time / 1000);
    return `${seconds} sec`;
  };

  return (
    <Container>
      <TitleSection>
        <IconStyled icon="Cog" color={"gray"} />
        <H1>
          <FormattedMessage {...messages.popup_params} />
        </H1>
      </TitleSection>

      <Param>
        <Title>
          <span>
            <FormattedMessage {...messages.popup_total_numbers} />
          </span>
          <SubTitle>
            <FormattedMessage {...messages.popup_from_to} /> {maxNumber}
          </SubTitle>
        </Title>
        <Action>
          <Input
            type="range"
            value={maxNumber}
            step={10}
            min="10"
            max="100"
            onChange={(e) => changeNumber(e.target.value)}
          />
        </Action>
      </Param>
      <Param>
        <Title>
          <FormattedMessage {...messages.popup_animation} />
        </Title>
        <Action>
          <SwitchStyled
            onChange={(checked) => changeAnimationAction(checked)}
            checked={animation}
            height={20}
            width={40}
          />
        </Action>
      </Param>
      {animation && (
        <>
          <Param>
            <Title>
              <span>
                <FormattedMessage {...messages.popup_animation_duration} />
              </span>
              <SubTitle>
                <FormattedMessage {...messages.popup_animation_second} />{" "}
                {convertTime(animationDuration)}
              </SubTitle>
            </Title>
            <Action>
              <Input
                type="range"
                value={animationDuration}
                step={1000}
                min="2000"
                max="6000"
                onChange={(e) => changeAnimationDurationAction(e.target.value)}
              />
            </Action>
          </Param>
          <Param>
            <Title>
              <FormattedMessage {...messages.popup_sound} />
            </Title>
            <Action>
              <SwitchStyled
                onChange={(checked) => changeSoundAction(checked)}
                checked={sound}
                height={20}
                width={40}
              />
            </Action>
          </Param>
        </>
      )}
      <Param>
        <Title>
          <FormattedMessage {...messages.popup_automatic_game} />
        </Title>
        <Action>
          <SwitchStyled
            onChange={(checked) => changeAutoPlayAction(checked)}
            checked={autoplay}
            height={20}
            width={40}
          />
        </Action>
      </Param>
      {autoplay && ( // Display only if autoplay is true
        <Param>
          <Title>
            <span>
              <FormattedMessage {...messages.popup_automatic_interval} />
            </span>
            <SubTitle>
              <FormattedMessage {...messages.popup_automatic_launch} />
              {convertTime(interval)}
            </SubTitle>
          </Title>
          <Action>
            <Input
              type="range"
              value={interval}
              step={5000}
              min="5000"
              max="120000"
              onChange={(e) => changeIntervalAction(e.target.value)}
            />
          </Action>
        </Param>
      )}
      <ParamsValid>
        {needToReload && ( // Alert user if he need to reload the game
          <Reload>
            <FormattedMessage {...messages.popup_need_to_reload} />
          </Reload>
        )}
        <Buttons>
          <Button onClick={() => reset()} width={50} height={30}>
            <FormattedMessage {...messages.popup_reload} />
          </Button>
          <Button onClick={() => setPopUp(false)} width={30} height={30}>
            <FormattedMessage {...messages.popup_back} />
          </Button>
        </Buttons>
      </ParamsValid>
    </Container>
  );
};
PopUp.propTypes = {
  setPopUp: PropTypes.func,
};

export default PopUp;
