// React
import React, { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
// Style
import styled from "styled-components";
// Actions
import { initialize, play, reset } from "../actions/loto";
// Components
import PopUp from "../components/Popup/Popup";
import Button from "../components/Button/Button";
import History from "../components/History/History";
// Utils
import Icon from "utils/icon";
import { useInterval } from "../utils/useInterval";
// Animation & sound
import BingoSound from "../sound/bingo.mp3";
import BingoAnimation from "../components/BingoAnimation/BingoAnimation";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "../messages/messages";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background: linear-gradient(
    162.44deg,
    #85b6ff 0%,
    rgba(106, 53, 255, 0.5) 100%
  );
`;

const Param = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: clamp(1.5em, 8vw, 150px);
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17%;
`;

const GameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 83%;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
  width: 80%;
  border: solid 3px white;
  border-radius: 50px;
  background: white;
`;

const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 30%;
  width: 80%;
`;

const HistoryTitle = styled.div`
  height: 30%;
  display: flex;
  color: #fff;
  font-size: clamp(30px, 5vw, 80px);
  align-items: center;
`;

const HistoryNumber = styled.div`
  display: flex;
  align-items: center;
  font-size: 30px;
  height: 40%;
  color: #fff;
`;

const DisplayNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80%;
`;

const DrawNumber = styled.span`
  color: #795bd0;
  font-size: clamp(30px, 7vw, 100px);
  height: 20%;
`;

const Number = styled.div`
  font-size: clamp(100px, 50vw, 400px);
  color: #2565C8;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  font-weight: bold;
`;

const NewDraw = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10%;
  width: 80%;
  background-color: #fff;
  border-radius: 50px;
`;

const TextStyled = styled.span`
  font-size: clamp(30px, 6vw, 100px);
  background-image: linear-gradient(
    90deg,
    rgba(106, 53, 255, 0.8) 0%,
    #85b6ff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const NumberHistory = styled.span`
  margin-right: 5%;
  font-size: clamp(30px, 6vw, 100px);
  ${(props) =>
    props.index == 0 &&
    `
    font-weight: bold;
    font-size: clamp(30px, 9vw, 150px);

  `}
`;

const IconStyled = styled(Icon)`
  width: 3vh;
  height: 3vh;
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const RemainTime = styled.span`
  font-style: italic;
`;

const IndexPage = () => {
  const [popUp, setPopUp] = useState(false);
  const [seeHistory, setSeeHistory] = useState(false);
  const [audio] = useState(typeof Audio !== "undefined" && new Audio(BingoSound));
  const [playingAnimation, setPlayingAnimation] = useState(false);
  const [startAutoPlay, setStartAutoPlay] = useState(false);
  const [pause, setPause] = useState(false);
  const [remainTime, setRemainTime] = useState(0);
  const {
    numbers,
    history,
    animation,
    animationDuration,
    sound,
    autoplay,
    interval,
    isFinish,
  } = useSelector((state) => state.loto);

  const dispatch = useDispatch();
  const initializeGame = () => dispatch(initialize());
  const playGame = (index) => dispatch(play(index));
  const resetGame = () => dispatch(reset());

  useEffect(() => {
    initializeGame();
  }, []);

  const playWithAnimation = (index) => {
    if (animation) {
      setPlayingAnimation(true);
      if (sound) {
        audio.play();
      }

      setTimeout(() => {
        setPlayingAnimation(false);
        audio.pause();
        audio.currentTime = 0;
      }, animationDuration);
    }
    playGame(index);
  };

  const resetGameAction = () => {
    resetGame();
    setPause(false);
    setStartAutoPlay(false);
    playWithAnimation(getRandom());
  };

  const getRandom = () => {
    const random = Math.floor(Math.random() * numbers.length);
    return random;
  };

  useInterval(
    () => {
      if (autoplay && startAutoPlay && !isFinish) {
        let newRemainTime = remainTime - 1000;
        console.log("newRemainTime", newRemainTime);
        setRemainTime(newRemainTime);
        if (remainTime == 0) {
          if (animation) {
            setRemainTime(interval + animationDuration);
          } else {
            setRemainTime(interval);
          }
          playWithAnimation(getRandom());
        }
      }
    },
    pause ? null : 1000
  );

  const displayTextButton = () => {
    if (startAutoPlay) {
      if (pause) {
        return (
          <>
            <FormattedMessage {...messages.loto_resume} />{" "}
            <RemainTime>{"(" + remainTime / 1000 + ")"}</RemainTime>
          </>
        );
      } else {
        return (
          <>
            <FormattedMessage {...messages.loto_pause} />{" "}
            <RemainTime>{"(" + remainTime / 1000 + ")"}</RemainTime>
          </>
        );
      }
    } else {
      if (autoplay) {
        return <FormattedMessage {...messages.loto_start_auto} />;
      } else {
        return <FormattedMessage {...messages.loto_draw_number} />;
      }
    }
  };

  const launchAction = () => {
    if (startAutoPlay) {
      if (pause) {
        setPause(false);
      } else {
        setPause(true);
      }
    } else {
      if (autoplay) {
        setStartAutoPlay(true);
        if (animation) {
          setRemainTime(interval + animationDuration);
        }
      }
      playWithAnimation(getRandom());
    }
  };

  return (
    <Container>
      <Title>
        <FormattedMessage {...messages.play_loto} />
      </Title>
      <GameContainer>
        <NumberContainer>
          {isFinish ? (
            <>
              <DrawNumber>
                <FormattedMessage {...messages.loto_finished} />
              </DrawNumber>
              <NewDraw onClick={() => resetGameAction()}>
                <TextStyled>
                  <FormattedMessage {...messages.loto_reload} />
                </TextStyled>
              </NewDraw>
            </>
          ) : playingAnimation ? (
            <BingoAnimation />
          ) : (
            <>
              <DisplayNumber>
                <DrawNumber>
                  {history.length ? (
                    <FormattedMessage {...messages.loto_number_drawn} />
                  ) : null}
                </DrawNumber>
                <Number>{history && history[0]}</Number>
              </DisplayNumber>
              <Button
                width={80}
                height={10}
                backgroundColor={"#a086ea"}
                text={displayTextButton()}
                action={() => launchAction()}
              />
            </>
          )}
        </NumberContainer>
        <HistoryContainer>
          <HistoryTitle>
            <FormattedMessage {...messages.loto_last_numbers} />
          </HistoryTitle>
          <HistoryNumber>
            {history.length != 0 &&
              history.slice(1, 6).map((number, i) => (
                <React.Fragment key={number}>
                  <NumberHistory index={i} key={number}>
                    {number}
                  </NumberHistory>
                </React.Fragment>
              ))}
            {history.length > 6 && (
              <IconStyled
                icon="ArrowCircleRight"
                onClick={() => setSeeHistory(true)}
              />
            )}
          </HistoryNumber>
        </HistoryContainer>
        <Param onClick={() => setPopUp(true)}>
          <IconStyled icon="Cog" color="#fff" />
        </Param>
      </GameContainer>

      {popUp && (
        <PopUp
          setPopUp={setPopUp}
          setRemainTime={setRemainTime}
          setPause={setPause}
          resetGame={resetGame}
          setStartAutoPlay={setStartAutoPlay}
        />
      )}

      {seeHistory && <History setSeeHistory={setSeeHistory} />}
    </Container>
  );
};

export default IndexPage;
