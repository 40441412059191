import { defineMessages } from "react-intl";
export default defineMessages({
  popup_params: {
    id: "popup.params",
    defaultMessage: "Parameters",
  },
  popup_total_numbers: {
    id: "popup.total.numbers",
    defaultMessage: "Total numbers",
  },
  popup_from_to: {
    id: "popup.from.to",
    defaultMessage: "Bingo from 1 to",
  },
  popup_animation: {
    id: "popup.animation",
    defaultMessage: "Animation",
  },
  popup_animation_duration: {
    id: "popup.animation.duration",
    defaultMessage: "Animation duration",
  },
  popup_animation_second: {
    id: "popup.animation.second",
    defaultMessage: "The animation will last",
  },
  popup_sound: {
    id: "popup.sound",
    defaultMessage: "Sound",
  },
  popup_automatic_game: {
    id: "popup.automatic.game",
    defaultMessage: "Automatic game",
  },
  popup_automatic_interval: {
    id: "popup.automatic.interval",
    defaultMessage: "Interval between each launch",
  },
  popup_automatic_launch: {
    id: "popup.automatic.launch",
    defaultMessage: "Launch every",
  },
  popup_need_to_reload: {
    id: "popup.need.to.reload",
    defaultMessage: "You need to reload the page to apply the changes",
  },
  popup_reload: {
    id: "popup.reload",
    defaultMessage: "Reload the game",
  },
  popup_back: {
    id: "popup.back",
    defaultMessage: "Back",
  },
  history_title: {
    id: "history.title",
    defaultMessage: "History of draws",
  },
});
