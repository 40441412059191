// action to dispatch
import {
  INITIALIZE_GAME,
  PLAY_GAME,
  RESET_GAME,
  CHANGE_MAX_NUMBER,
  CHANGE_ANIMATION,
  CHANGE_ANIMATION_DURATION,
  CHANGE_SOUND,
  CHANGE_AUTOPLAY,
  CHANGE_INTERVAL,
} from "../state/loto";

// Initialize game
export const initialize = () => {
  return (dispatch) => {
    dispatch({ type: INITIALIZE_GAME });
  };
};

// Play game
export const play = (index) => {
  return (dispatch) => {
    dispatch({ type: PLAY_GAME, payload: index });
  };
};

// Reset game
export const reset = () => {
  return (dispatch) => {
    dispatch({ type: RESET_GAME });
  };
};

// Change max number
export const changeMaxNumber = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_MAX_NUMBER, payload: value });
  };
};

// Enable or disable animation
export const changeAnimation = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_ANIMATION, payload: value });
  };
};

// Change animation duration
export const changeAnimationDuration = (value) => {
  return (dispatch) => {
    console.log("value", value);
    dispatch({ type: CHANGE_ANIMATION_DURATION, payload: parseInt(value) });
  };
};

export const changeSound = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_SOUND, payload: value });
  };
};

// Enable or disable autoplay
export const changeAutoPlay = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_AUTOPLAY, payload: value });
  };
};

// Change interval of autoplay
export const changeInterval = (value) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_INTERVAL, payload: parseInt(value) });
  };
};
