//React
import React, { useEffect, useState } from "react";
//Style
import styled from "styled-components";
// Redux
import { useSelector } from "react-redux";
// i18n
import { FormattedMessage } from "react-intl";
import messages from "../Popup/messages";

const Container = styled.button`
  position: fixed;

  left: 5%;
  top: 10%;

  width: 90%;
  height: 80%;

  border: 2px solid #011627;
  border-radius: 1em;

  background-color: #ffffff;

  display: flex;
  flex-direction: column;
  align-items: center;

  color: #000;
`;

const TitleSection = styled.div`
  display: flex;
  width: 95%;
  height: 7%;
  font-size: clamp(1.5em, 5vw, 150px);
  align-items: center;
  padding: 15px 0px;
`;

const DisplayHistory = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: clamp(10px, 4vw, 50px);
  flex-wrap: wrap;
  overflow-y: auto;
`;

const Number = styled.div`
  display: flex;
  width: 16%;
  justify-content: center;
  align-items: center;
  border: solid 1px gray;
  padding: 4% 0px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  width: 100%;
  height: 10%;
`;

const Button = styled.div`
  background: linear-gradient(
    162.44deg,
    #85b6ff 0%,
    rgba(106, 53, 255, 0.5) 100%
  );
  font-size: clamp(20px, 5vw, 70px);
  border-radius: 50px;
  height: 100%;
  padding: 3% 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height}%;
  width: ${(props) => props.width}%;
  color: #fff;
`;

const History = ({ setSeeHistory }) => {
  const { history } = useSelector((state) => state.loto);
  const [numbersHistory, setNumbersHistory] = useState([]);

  useEffect(() => {
    const newNumbers = history.sort((a, b) => {
      return a - b;
    });
    setNumbersHistory(newNumbers);
  });

  return (
    <Container>
      <TitleSection>
        <span>
          <FormattedMessage {...messages.history_title} />
        </span>
      </TitleSection>
      <DisplayHistory>
        {numbersHistory.map((number) => (
          <Number key={number}>
            <span>{number}</span>
          </Number>
        ))}
      </DisplayHistory>
      <Buttons>
        <Button height={50} width={50} onClick={() => setSeeHistory(false)}>
          <FormattedMessage {...messages.popup_back} />
        </Button>
      </Buttons>
    </Container>
  );
};

export default History;
