import { defineMessages } from "react-intl";
export default defineMessages({
  play_loto: {
    id: "loto.play",
    defaultMessage: "Play Loto",
  },
  loto_finished: {
    id: "loto.finished",
    defaultMessage: "Loto finished !",
  },
  loto_reload: {
    id: "loto.reload",
    defaultMessage: "Restart the game",
  },
  loto_number_drawn: {
    id: "loto.number.drawn",
    defaultMessage: "Number drawn",
  },
  loto_draw_number: {
    id: "loto.draw.number",
    defaultMessage: "Draw a number",
  },
  loto_last_numbers: {
    id: "loto.last.numbers",
    defaultMessage: "Last numbers drawn",
  },
  loto_start_auto: {
    id: "loto.start.auto",
    defaultMessage: "Start game",
  },
  loto_resume: {
    id: "loto.resume",
    defaultMessage: "Resume",
  },
  loto_pause: {
    id: "loto.pause",
    defaultMessage: "Pause",
  },
});
